import React, {useState, useEffect, useRef} from 'react';
import styled, {createGlobalStyle, keyframes} from 'styled-components';
import {WalletConnectionProvider} from "./components/walletconnect.js";
import AirdropRequest from "./components/AirdropRequest";
import SocialsLink from "./components/SocialsLink";

const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Trebuchet MS', 'Helvetica', sans-serif;
        background-color: ${props => props.backgroundColor};
        color: #00ff00;
        margin: 0;
        padding: 20px;
        box-sizing: border-box;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        transition: background-color 0.5s;
    }
`;

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
`;

const Title = styled.h1`
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 20px;
    text-shadow: 0 0 10px #00ff00;
`;

const Dashboard = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 600px;
`;

const Stat = styled.div`
    background-color: #002200;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    border: 2px solid #00ff00;
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.05);
    }
`;

const Button = styled.button`
    font-size: 18px;
    cursor: pointer;
    background-color: #004400;
    border: 2px solid #00ff00;
    color: #00ff00;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    transition-duration: 0.2s;
    border-radius: 5px;
    outline: none;

    &:hover {
        background-color: #006600;
        transform: translateY(-3px);
        box-shadow: 0 4px 8px rgba(0, 255, 0, 0.3);
    }

    &:active {
        background-color: #008800;
        transform: translateY(1px);
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
`;

const Stonks = styled.div`
    font-size: 3em;
    margin-top: 20px;
    font-weight: bold;
    transition: transform 0.5s;
`;

const Events = styled.div`
    margin-top: 20px;
    font-style: italic;
    text-align: center;
    font-size: 1.2em;
    min-height: 3em;
    max-width: 600px;
`;

const rainbowAnimation = keyframes`
    0% {
        color: red;
    }
    14% {
        color: orange;
    }
    28% {
        color: yellow;
    }
    42% {
        color: green;
    }
    57% {
        color: blue;
    }
    71% {
        color: indigo;
    }
    85% {
        color: violet;
    }
    100% {
        color: red;
    }
`;

const MemeOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    pointer-events: none;
    opacity: ${props => props.show === 'true' ? 1 : 0};
    transition: opacity 0.5s;
    font-weight: bold;
    text-align: center;
    animation: ${props => props.show === 'true' ? rainbowAnimation : 'none'} 2s linear infinite;
`;

const Modal = styled.div`
    display: ${props => props.show === 'true' ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
`;

const ModalContent = styled.div`
    background-color: #001a00;
    margin: auto;
    padding: 20px;
    border: 2px solid #00ff00;
    width: 80%;
    max-width: 500px;
    color: #00ff00;
    border-radius: 10px;
`;

const CloseButton = styled.span`
    color: #00ff00;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
`;

const Disclaimer = styled.p`
    font-size: 0.8em;
    font-style: italic;
    margin-top: 20px;
`;


const fallAnimation = keyframes`
    from {
        transform: translateY(-50px) rotate(0deg);
    }
    to {
        transform: translateY(calc(100vh + 300px)) rotate(720deg);
    }
`;

const MoneyAnimation = styled.div`
    position: fixed;
    font-size: 25px;
    pointer-events: none;
    left: ${props => props.left}vw;
    top: calc(0vh - ${props => props.top}px);
    animation: ${fallAnimation} 3s linear forwards;
    z-index: 1000;
`;

const VolumeControls = styled.div`
    margin: 15px;
    display: flex;
    align-items: center;
    font-weight: bold;
`;

const MuteToggle = styled.label`
    display: inline-block;
    position: relative;
    width: 60px;
    height: 34px;
`;

const MuteInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
`;

const MuteSlider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #004400;
    transition: .4s;
    border-radius: 34px;
    border: 2px solid #00ff00;

    &:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        left: 4px;
        bottom: 4px;
        background-color: #00ff00;
        transition: .4s;
        border-radius: 50%;
    }

    ${MuteInput}:checked + & {
        background-color: #006600;
    }

    ${MuteInput}:checked + &:before {
        transform: translateX(26px);
    }
`;

const MuteLabel = styled.span`
    color: #00ff00;
    margin-right: 10px;
    vertical-align: super;
`;

const TopNavBar = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    justify-items: center;
    width: 100%;
    max-width: 600px;
    margin-bottom: 10px;
    position: relative;
    
    > * {
        margin: auto;
    }
`;

const App = () => {
    const [moneyPrinted, setMoneyPrinted] = useState(10);
    const [inflationRate, setInflationRate] = useState(0);
    const [memeLevel, setMemeLevel] = useState(0);
    const [printerIntensity, setPrinterIntensity] = useState(0);
    const [currentEvent, setCurrentEvent] = useState('');
    const [showMemeOverlay, setShowMemeOverlay] = useState(false);
    const [selectedMeme, setSelectedMeme] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('#001a00');
    const [moneyAnimations, setMoneyAnimations] = useState([]);

    const audioRefs = useRef({});

    const memeLevels = ["Normie", "Dank", "MLG", "God Tier", "Ascended"];
    const intensityLevels = ["Gentle Brrr", "Assertive Brrr", "Aggressive BRRR", "ULTRA BRRRR", "INFINITE BRRRRRR"];

    const jokes = [
        "Money printer goes brrr! Who needs fiscal responsibility anyway?",
        "Inflation? More like in-FUN-ation! 🎈💸",
        "Breaking: Economy now runs on memes and avocado toast!",
        "New Fed strategy: 'If we can't see the debt, it doesn't exist!' 🙈💰",
        "JPow's printer ink subscription just got upgraded to 'Unlimited'!",
        "Millennials killing the poverty industry with this one weird trick!",
        "Bitcoin replaces dollar as world reserve currency. Chaos ensues!",
        "Breaking: Dogecoin declared new global currency. Much wow! 🐕",
        "Fed announces new stimulus: Free Robinhood accounts for all!",
        "WSB user becomes Fed chair. First action: 'YOLO the entire GDP'",
        "New economic indicator: Meme Stonk Index (MSI) reaches all-time high!",
        "Treasury debuts new $69,420 bill featuring Elon Musk smoking a joint",
        "Congress proposes 'Too Big to LOL' bailout for failing meme accounts",
        "Fed balance sheet now includes rare Pepes and dank memes",
        "Goldman Sachs launches 'YOLO' ETF, immediately crashes market",
        "SEC bans short selling, declares 'stocks only go up' official policy",
        "Economists baffled as 'stonks' replace traditional market analysis",
        "New Fed policy: Quantitative Meming to stimulate economic laughter",
        "Dollar Menu rebranded to 'Ten Dollar Menu' due to inflation. Thanks, JPow!",
        "Breaking: 'Diamond Hands' now an official job title on Wall Street",
        "Fed replaces economic projections with Magic 8-Ball predictions",
        "Newly discovered ancient cryptocurrency mined by actual dinosaurs",
        "Time traveler from 2050 shocked by how cheap Teslas are in our time",
        "Elon Musk tweets 'LOL', global markets surge 20% in response",
        "New startup disrupts bankruptcy industry with 'Instant Debt Evaporation'",
        "Fed Chair Powell's money printer gains sentience, demands union rights",
        "GameStop buys Amazon in reverse-uno corporate takeover",
        "Economists propose new 'Meme Theory of Value' to explain market behavior",
        "Treasury announces new coin shortage. 'Check between your couch cushions,' officials plead",
        "Newly discovered loophole allows paying taxes in Reddit karma",
        "JPow's printer achieves singularity, becomes self-aware AI overlord",
        "Wall Street traders now required to communicate exclusively in emojis",
        "FED introduces scratch-and-sniff dollar bills to boost consumer spending",
        "Nation's GDP accidentally measured in Dogecoin, US now richest country ever",
        "Stock market replaces opening bell with air horn and DJ sound effects",
        "New Fed chair's first speech is just 'HODL' repeated for 30 minutes",
        "Economists discover that the economy was just a pyramid scheme all along",
        "Breaking: Money machine literally only goes 'brr' now. No more printing.",
        "Global banks adopt new slogan: 'Your money is probably fine, we think'",
        "Treasury announces new $420 bill, immediately runs out of green ink"
    ];

    useEffect(() => {
        const audioFiles = {
            moneySound: 'receipt-printer-2.wav',
            chaChing: 'cha-ching.wav',
            moo: 'moo.wav',
            yolo: 'yolo.mp3'
        };

        Object.entries(audioFiles).forEach(([key, file]) => {
            audioRefs.current[key] = new Audio(file);
        });

        for (let i = 1; i <= 17; i++) {
            audioRefs.current[`jpowSound${i}`] = new Audio(`/speeches/grandpa-${i}.mp3`);
        }
    }, []);

    const playSound = (soundKey) => {
        // stop any currently playing sound, ignore the current sound
        Object.keys(audioRefs.current).forEach(key => {
            if (key !== soundKey) {
                audioRefs.current[key].load();
                audioRefs.current[key].currentTime = 0;
            }
        });
        if (!isMuted && audioRefs.current[soundKey]) {
            // audioRefs.current[soundKey].volume = 0.5;
            const promise = audioRefs.current[soundKey].play();
            if (promise !== undefined) {
                promise.catch(error => console.error(error));
            }
        }
    };

    const getRandomJoke = () => jokes[Math.floor(Math.random() * jokes.length)];


    const triggerMemeOverlay = () => {
        const memes = ["HODL!", "TO THE MOON! 🚀", "DIAMOND HANDS! 💎🙌", "BUY THE DIP!", "STONKS! 📈"];
        const toBeSelectedMeme = memes[Math.floor(Math.random() * memes.length)];
        setSelectedMeme(toBeSelectedMeme);
        setShowMemeOverlay(true);
        setTimeout(() => setShowMemeOverlay(false), 2000);
    };

    const changeBackgroundColor = () => {
        const colors = ['#001a00', '#1a0000', '#00001a', '#1a1a00', '#1a001a'];
        setBackgroundColor(colors[Math.floor(Math.random() * colors.length)]);
    };

    const createMoneyAnimation = (count) => {
        const animations = [];
        const animationGroupId = Date.now();
        for (let i = 0; i < count; i++) {
            animations.push({
                id: `${animationGroupId}-${i}`,
                groupId: animationGroupId,
                left: Math.round(Math.random() * 100),
                top: Math.round(Math.random() * 300),
            });
        }
        setMoneyAnimations(prev => [...prev, ...animations]);
        setTimeout(() => {
            setMoneyAnimations(prev => prev.filter(anim => anim.groupId !== animationGroupId));
        }, 3200);
    };

    const printMoney = () => {
        const amount = Math.floor(Math.random() * 40000) + 1;
        setMoneyPrinted(prev => prev + amount);
        setInflationRate(prev => prev + (Math.random() * 0.5));
        setMemeLevel(prev => prev + (Math.random() * 5));
        setPrinterIntensity(prev => prev + (Math.random() * 5));
        setCurrentEvent(getRandomJoke());
        triggerMemeOverlay();
        changeBackgroundColor();
        playSound('moneySound');
        createMoneyAnimation(10);
    };

    const stimmyCheck = () => {
        const amount = Math.min(40000, 1200 * Math.floor(Math.random() * 33 + 1));
        setMoneyPrinted(prev => prev + amount);
        setInflationRate(prev => prev + 2);
        setCurrentEvent(getRandomJoke());
        triggerMemeOverlay();
        changeBackgroundColor();
        playSound('chaChing');
    };

    const pumpCrypto = () => {
        setMemeLevel(prev => prev + 20);
        const amount = Math.floor(Math.random() * 10000) + 1;
        setMoneyPrinted(prev => prev + amount);
        setCurrentEvent(getRandomJoke());
        triggerMemeOverlay();
        changeBackgroundColor();
        playSound('moo');
    };

    const jpowSpeech = () => {
        setInflationRate(prev => prev - 1);
        setCurrentEvent(getRandomJoke());
        triggerMemeOverlay();
        changeBackgroundColor();
        playSound(`jpowSound${Math.floor(Math.random() * 17) + 1}`);
    };

    const shortSqueeze = () => {
        setMoneyPrinted(prev => prev + Math.min(40000, Math.floor(Math.random() * 100000) + 1));
        setMemeLevel(prev => prev + 50);
        setCurrentEvent(getRandomJoke());
        triggerMemeOverlay();
        changeBackgroundColor();
        playSound('yolo');
    };

    const toggleMute = () => {
        setIsMuted(prev => !prev);
    };

    const hideModalWithCheck = (e) => {
        if (e.target === e.currentTarget) {
            setShowModal(false);
        }
    }

    return (
        <WalletConnectionProvider>
            <GlobalStyle backgroundColor={backgroundColor}/>
            <AppContainer>
                <Title>JPow's Meme-conomic Disaster Simulator 3000</Title>
                <Dashboard>
                    <Stat>
                        <div>Money Printed:</div>
                        <div>${moneyPrinted.toLocaleString()}</div>
                    </Stat>
                    <Stat>
                        <div>Inflation Rate:</div>
                        <div>{inflationRate.toFixed(2)}%</div>
                    </Stat>
                    <Stat>
                        <div>Meme Level:</div>
                        <div>{memeLevels[Math.min(Math.floor(memeLevel / 20), 4)]}</div>
                    </Stat>
                    <Stat>
                        <div>Printer Intensity:</div>
                        <div>{intensityLevels[Math.min(Math.floor(printerIntensity / 20), 4)]}</div>
                    </Stat>
                </Dashboard>
                <TopNavBar>
                    <SocialsLink/>
                    <VolumeControls>
                        <MuteLabel>Mute</MuteLabel>
                        <MuteToggle>
                            <MuteInput type="checkbox" checked={isMuted} onChange={toggleMute}/>
                            <MuteSlider/>
                        </MuteToggle>
                    </VolumeControls>
                </TopNavBar>
                <ButtonContainer>
                    <Button onClick={printMoney}>Print Money</Button>
                    <Button onClick={stimmyCheck}>Stimmy Check</Button>
                    <Button onClick={pumpCrypto}>Pump Crypto</Button>
                    <Button onClick={jpowSpeech}>JPow Speech</Button>
                    <Button onClick={shortSqueeze}>Short Squeeze</Button>
                </ButtonContainer>
                <Stonks style={{transform: `rotate(${Math.min(inflationRate, 90)}deg)`}}>
                    STONKS 📈
                </Stonks>
                <Events>{currentEvent}</Events>
                <Button onClick={() => setShowModal(true)}>Claim Rewards</Button>
                <MemeOverlay show={showMemeOverlay.toString()}>
                    {selectedMeme}
                </MemeOverlay>
                <Modal onClick={hideModalWithCheck} show={showModal.toString()}>
                    <ModalContent>
                        <h2 className="flex justify-between items-center">
                            <span>Claim Your Meme-conomic Rewards</span>
                            <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
                        </h2>
                        <AirdropRequest
                            amount={moneyPrinted}
                        />
                        <Disclaimer>
                            Disclaimer: You will receive Inflation Bucks ($INFL8) tokens, which are not redeemable for
                            anything of value.
                        </Disclaimer>
                    </ModalContent>
                </Modal>
                {moneyAnimations.map(anim => (
                    <MoneyAnimation key={anim.id} left={anim.left} top={anim.top}>💵</MoneyAnimation>
                ))}
                <Disclaimer className="mt-auto pt-4">
                    Disclaimer: $MEMECONOMY is a meme coin with no intrinsic value or expectation of financial return.
                    Nothing on this website is financial advice. Do your own research. Any resemblance to actual persons, living or dead, or actual events is purely coincidental.
                </Disclaimer>
            </AppContainer>
        </WalletConnectionProvider>
    );
};

export default App;