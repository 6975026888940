import React, {useState, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    background-color: #001a00;
    color: #00ff00;
    font-family: 'Trebuchet MS', 'Helvetica', sans-serif;
`;

const Spinner = styled.div`
    border: 4px solid #00ff00;
    border-top: 4px solid #001a00;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${spin} 1s linear infinite;
    margin-bottom: 20px;
`;

const LoadingText = styled.div`
    font-size: 1.2em;
    text-align: center;
    max-width: 80%;
`;

const funnyLoadingMessages = [
    "Teaching JPow's printer to twerk...",
    "Replacing ink with liquidated assets...",
    "Inflating away your financial worries...",
    "Turning on fan to help money printer cool down...",
    "Convincing Congress money does grow on trees...",
    "Replacing 'In God We Trust' with 'In BRRR We Trust'...",
    "Updating Fed's Twitch stream: Money Printer % Speedrun...",
    "Transforming your savings into future collectibles...",
    "Hiring Pixar to animate our line charts going up...",
    "Redefining 'transitory' for the 69th time this week..."
];

const FunnyMoneyLoader = () => {
    const [currentMessage, setCurrentMessage] = useState(funnyLoadingMessages[0]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentMessage(prevMessage => {
                const currentIndex = funnyLoadingMessages.indexOf(prevMessage);
                const nextIndex = (currentIndex + 1) % funnyLoadingMessages.length;
                return funnyLoadingMessages[nextIndex];
            });
        }, 3000); // Change message every 3 seconds

        return () => clearInterval(intervalId);
    }, []);

    return (
        <LoaderContainer>
            <Spinner/>
            <LoadingText>{currentMessage}</LoadingText>
        </LoaderContainer>
    );
};

export default FunnyMoneyLoader;