import React from 'react';
import styled from "styled-components";
import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import {useWallet} from "@solana/wallet-adapter-react";
import * as PropTypes from "prop-types";
import {UnsafeBurnerWalletAdapter} from "@solana/wallet-adapter-wallets";
import bs58 from "bs58";
import api from "../api";
import FunnyLoader from "./FunnyLoader";
import SocialsLink from "./SocialsLink";

const MockInterface = styled.div`
    background-color: #002200;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
`;


const ClaimButton = styled.button`
    font-size: 18px;
    cursor: pointer;
    background-color: #004400;
    border: 2px solid #00ff00;
    color: #00ff00;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    transition-duration: 0.2s;
    border-radius: 5px;
    outline: none;

    &:hover {
        background-color: #00ff00;
        color: #004400;
    }
`;


const PrivateKeyButton = styled.button`
    font-size: 12px;
    cursor: pointer;
    background-color: #004400;
    border: 2px solid #00ff00;
    color: #00ff00;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    transition-duration: 0.2s;
    border-radius: 5px;
    outline: none;

    &:hover {
        background-color: #00ff00;
        color: #004400;
    }
`;


function ClickToCopy(props) {
    const {children} = props;
    const [copied, setCopied] = React.useState(false);

    return (
        <span onClick={() => {
            navigator.clipboard.writeText(children);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }} className="break-all bg-black bg-opacity-35 block p-2 rounded cursor-alias">
            {children}
            {copied && <span className="text-green-500 transition-all ">&nbsp;Copied!</span>}
        </span>
    );
}

ClickToCopy.propTypes = {children: PropTypes.node};
export const AirdropRequest = (props) => {
    const {amount: moneyPrinted} = props;
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [airdropRequested, setAirdropRequested] = React.useState(false);
    const [showPrivateKey, setShowPrivateKey] = React.useState(false);
    const walletInfo = useWallet();

    const {wallet, signMessage, publicKey} = walletInfo;

    if (moneyPrinted === 0) {
        return (
            <MockInterface>
                <p>
                    You have not printed any money yet. <br/>
                    Go back and print some money!
                </p>
            </MockInterface>
        );
    }

    const isBurnerWallet = wallet && wallet?.adapter instanceof UnsafeBurnerWalletAdapter;

    const handleRequestAirdrop = async () => {
        setError(null);
        setLoading(true);

        try {
            // Make sure the wallet is connected
            if (!publicKey) {
                alert("Please connect your wallet first!");
                return;
            }

            const timestamp = new Date().getTime();

            // The message you want the user to sign
            const message = new TextEncoder().encode(
                `Warm the printer and give me some money!\nNow:${timestamp}`
            );

            // Request the user to sign the message
            const signedMessage = await signMessage(message);


            const b58Sign = bs58.encode(signedMessage);


            const payload = {
                address: publicKey.toString(),
                signature: b58Sign,
                amount: moneyPrinted,
                timestamp,
            }

            const response = await api.post('/request-airdrop', payload);

            console.log("response", response);

            setAirdropRequested(true);
            setLoading(false);

        } catch (error) {
            console.error("Error requesting airdrop", error);
            setLoading(false);
            if (error && error.message) {
                if (error.response && error.response.data) {
                    const errorMessage = error?.response?.data?.error ?? "Unknown error";
                    setError(errorMessage);
                } else {
                    setError(`Error signing message: ${error.message}`);
                }
            }

        }
    };


    if (airdropRequested) {
        return (
            <MockInterface>
                <p>
                    Airdrop requested successfully! <br/>
                    You should be a millionaire on paper soon!
                </p>
                <br/>
                <div className="flex justify-start flex-col">
                    Follow us for updates:<br/>
                    <SocialsLink />
                </div>
            </MockInterface>
        );
    }

    if (loading) {
        return <FunnyLoader/>
    }

    return (
        <MockInterface>
            <p>Available Balance:&nbsp;&nbsp;
                <b>${moneyPrinted.toLocaleString()}</b>
            </p><br/>
            <p className="text-sm">
                Alert: ${moneyPrinted.toLocaleString()} in $INFL8 tokens printed!<br/>
                Valuable only for bragging rights!
            </p>
            <br/>
            <WalletMultiButton/>
            <br/>
            {(wallet && isBurnerWallet) && (
                <>
                    <br/>
                    <p>Since you are using a burner wallet, you can reveal the private key below. (We never store the
                        details)</p><br/>
                    <PrivateKeyButton onClick={() => setShowPrivateKey(!showPrivateKey)}>
                        {showPrivateKey ? "Hide" : "Reveal"} Private Key
                    </PrivateKeyButton>
                    <br/>
                    {showPrivateKey && (
                        <ClickToCopy>
                            {bs58.encode(wallet?.adapter?._keypair?.secretKey ?? new Uint8Array(0))}
                        </ClickToCopy>
                    )}
                </>
            )}
            <br/>
            {wallet && (<>
                <p className="mb-4">Wallet selected: <ClickToCopy>{publicKey?.toString()}</ClickToCopy></p>
                <ClaimButton onClick={handleRequestAirdrop}>
                    Request Airdrop
                </ClaimButton>
            </>)}
            {error && (
                <p className="text-red-600">{error}</p>
            )}
        </MockInterface>
    );
}

export default AirdropRequest;