import React from 'react';
import styled from "styled-components";
import {Twitter, X} from "lucide-react";
import {ReactComponent as DexScreener} from "./dex-screener-seeklogo.svg";
import pumpFunLogo from "./pump-fun.jpg";

const SocialIcons = styled.a`
    a {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid #00ff00;

        &:hover {
            transform: scale(1.1);
        }
        
        &.twitter svg {
            stroke-width: 0;
        }

        svg {
            width: 25px;
            height: 25px;
            fill: white;
            color: white;
        }
        
        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
`;


const SocialsLink = (props) => {
    const tg_link = process.env.REACT_APP_TELEGRAM_LINK;
    const tw_link = process.env.REACT_APP_TWITTER_LINK;
    const pumpfun_link = process.env.REACT_APP_PUMPFUN_LINK;
    const dex_link = process.env.REACT_APP_DEX_LINK;

    return (
        <SocialIcons className="flex gap-x-4 items-center mt-4 flex-wrap">
            {tg_link && (
                <a href={tg_link} target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            d="M9.78,18.65L10.06,14.42L17.74,7.5C18.08,7.19 17.67,7.04 17.22,7.31L7.74,13.3L3.64,12C2.76,11.75 2.75,11.14 3.84,10.7L19.81,4.54C20.54,4.21 21.24,4.72 20.96,5.84L18.24,18.65C18.05,19.56 17.5,19.78 16.74,19.36L12.6,16.3L10.61,18.23C10.38,18.46 10.19,18.65 9.78,18.65Z"/>
                    </svg>
                </a>
            )}
            {tw_link && (
                <a href={tw_link} target="_blank" className="twitter" rel="noopener noreferrer">
                    <Twitter size={50} />
                </a>
            )}
            {pumpfun_link && (
                <a href={pumpfun_link} target="_blank" rel="noopener noreferrer">
                    <img src={pumpFunLogo} alt="pump fun"/>
                </a>
            )}
            {dex_link && (
                <a href={dex_link} target="_blank" rel="noopener noreferrer">
                    <DexScreener />
                </a>
            )}
        </SocialIcons>
    );
}

export default SocialsLink;